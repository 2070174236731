@import url("https://fonts.googleapis.com/css2?family=Roboto+Condense&family=Madimi+One&display=swap");

:root {
  --dark: #597445;
  --main: #658147;
  --main600: #658147e0;
  --main500: #516938e0;
  --main400: #729762;
  --main300: #3d5029c5;
  --main200: #3d5029a0;
  --main100: #3d5029a0;
  --main50: #E7F0DC;

  --accentMain: rgb(246, 255, 0);   
  --accentSecondary: #e6ff79;  
  --rgba: rgba(89, 116, 69, .7);
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--main600);
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form {
  background: #ffffff;
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  letter-spacing: 1.3px;
}

.logo p {
  font-size: 18px;
  font-family: "Madimi One", sans-serif;
  color: #ffffff;
}

.gradient-text {
  background: -webkit-linear-gradient(120deg, #7b5fac, #f7c978);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}

.MuiContainer-root {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.css-h4y409-MuiList-root {
  height: 100%;
  background: var(--main);
  padding: 1rem!important;
}

.modal .MuiDialog-paper {
  background: var(--main);
}

.modal p, .modal h2, .modal h3, .modal h4, .modal h5, .modal h6 {
  color: #ffffff;
}

.css-1hp60fq {
  background-color: var(--dark)!important;
}

.MuiInputBase-input, .css-1x5jdmq {
  color: #000000b5!important;
}

.css-1gdepfy-MuiInputBase-root-MuiOutlinedInput-root, .MuiInputBase-root, .MuiOutlinedInput-root {
  border: .1px solid transparent!important;
}

.css-1gdepfy-MuiInputBase-root-MuiOutlinedInput-root:hover, .MuiInputBase-root:hover, .MuiOutlinedInput-root:hover {
  border: .1px solid var(--main)!important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--dark)!important;
}

.about-page {
  background: var(--rgba), url('./assets/bg.jpg');
  background-size: contain;
  background-repeat: repeat;
  background-position: center;
}

@media screen and (max-width: 768px) {
  .MuiContainer-root {
      padding-top: 2rem;
      padding-bottom: 2rem;
  }
  .css-rmcv22 {
    font-size: 1.5rem!important;
  }
  .css-1666g5d {
    font-size: 1.2rem!important;
  }
}

/* cookie modal */
.cookie-block {
  position: fixed;
  bottom: 0;
  right: 0;
  max-width: 8xl;
  border-radius: 0.5rem;
  background-color: var(--dark);
  opacity: 0.9;
  color: #fff;
  margin: 0.625rem;
  margin-bottom: 0.25rem;
  padding: 1.25rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 10;
}

.cookie-text {
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.cookie-accept {
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: var(--accentMain);
  color: var(--white);
  border-radius: 0.25rem;
  border: 0;
  padding: 0.5rem 1rem;
  margin-left: 0.625rem;
  font-size: 1em;
  cursor: pointer;
}

.cookie-close-wrap {
  border-radius: 0.625rem;
  position: absolute;
  bottom: -5%;
  right: 1%;
  transform: translateY(-50%);
}

.cookie-close-btn {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0.25rem 1rem;
  margin-left: 0.625rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--main100)
}

.cookie-close-btn:hover {
  transform: scale(1.25);
}

.cookie-more {
  color: #fff;
  margin-left: 0.125rem;
  text-decoration: underline;
  text-underline-offset: 0.125rem;
  font-size: 0.9em;
}

/* cookie modal */

.no-money-disclaimer {
  position: fixed;
  bottom: 0;
  left: 0;
  color: white; /* White text color */
  padding: 10px;
  display: flex;
  align-items: center;
}

.nomoney-icon {
  width: 20px;
  margin-right: 1rem;
}

.message {
  font-size: 14px;
  font-weight: 900;
  margin-left: 1rem;
  text-shadow: 3px 3px 2px rgba(128, 0, 0, 1);
}

